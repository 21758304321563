import React, { Component } from 'react'
import Tabs from './Tabs/Tabs'
import Cards from './Cards/Cards'

class Work extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 'all',
      limitChards: 500,
      projects: [
        {
          name: 'ACCENTURE',
          position: 'INTERN',
          description: 'A six month internship at Liquid Studio, the tech innovation department of Accenture, and the one of the best teams of engineers to be mentored by.',
          skills: ['NodeJS', 'JS (ReactJS)', 'CSS (Bulma)', 'DynamoDB (AWS)', 'Cognito (AWS)', 'Lambda (AWS)', 'API Gateway (AWS)', 'Scrum'],
          time: 'Feb 2020 - Jul 2020',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/accenture-logo.png?alt=media&token=061e2518-71e7-424d-b5ba-f937ee5c32b3',
          id: 1,
        },
        {
          name: 'CODAM (42)',
          position: 'COMPUTER SCIENCE STUDENT',
          description:
            'At Codam I studied computer science starting from its basics by recreating the C standard library, to complex algorithms all written in C. Codam is a part of the 42 network, which pioneers in new methods of education, with no teachers or classes, learning at 42 is learning by working on projects and with your peers',
          skills: ['C', 'Algorithms', 'Project Management', 'Shell'],
          time: 'Jan 2019 - Present',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/codam-logo.svg?alt=media&token=85aa2466-d96f-421b-b26a-cd7429acadbb',
          id: 2,
        },
        {
          name: 'BEATRIZ BREMER',
          position: 'CREATIVE FREELANCER',
          description: 'My first experience freelancing, where I touched different dimensions of creativity with a great diversity of clients.',
          skills: ['Video Editing', 'Animation', 'Project Management', 'Digital Strategy', 'Design'],
          time: 'May 2018 - Dez 2018',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/Beatriz.png?alt=media&token=4918a280-e27b-4f85-a304-93bbdd3ee875',
          id: 3,
        },
        {
          name: 'S2M',
          position: 'CONTENT PRODUCER',
          description: 'Doing a bit of everything in a startup like company with more than 30 years, from social media management, to oversseing photoshoots and my start in coding.',
          skills: ['Content Production', 'Project Management', 'Digital Strategy', 'Newsletter Editing', 'Social Media Managment', 'Writing'],
          time: 'Feb 2016 - Apr 2018',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/seats2meet-logo.png?alt=media&token=7bdd6f3a-8066-47a5-b058-3cd998065d3f',
          id: 4,
        },
        {
          name: 'VIACOMCBS',
          position: 'EXECUTIVE PRODUCTION ASSISTANT',
          description:
            'I worked with immense projects with equaly sized impact. During my time at Viacom I worked in 4 Brazilian Kids Choice Awards with Nickelodeon, the rebranding of MTV Brazil, the launch of Comedy Central and Paramount Channel, Emmy nominated show Adotada, among many others.',
          skills: ['TV Production', 'Project Management'],
          time: 'Apr 2012 - Apr 2016',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/ViacomCBS-logo.svg?alt=media&token=9fca0fdf-e221-4e19-bb59-79a052167567',
          id: 5,
        },
        {
          name: 'SYMBIA',
          position: 'ACCOUNT EXECUTIVE INTERN',
          description:
            'On my first internship, at 17 years old, I worked on assisting on projects with big brands such as Johnson & Johnson and Kraft Heinz. There I had direct contact with clients, did briefing of the creative team, budgeting and project management.',
          skills: ['Marketing', 'Project Management', 'Briefing', 'Communication Strategy'],
          time: 'Dez 2010 - Mar 2012',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/Symbia-logo.png?alt=media&token=59fc8caa-d7e9-4612-84e7-96cb0eae8c43',
          id: 6,
        },
        {
          name: 'FAAP',
          position: 'COMMUNICATION SCIENCE AND MEDIA STUDENT',
          description: 'I got my bachelors in Communication Science and Media studies at one of the best communication colleges in Latin America, where I got lots of hands on experience.',
          skills: ['Communication Science', 'Radio Production', 'TV Production', 'Directing', 'Animation', 'Video Editing'],
          time: 'Feb 2011 - Nov 2013',
          image: 'https://firebasestorage.googleapis.com/v0/b/beatrizbremer-8d455.appspot.com/o/faap-logo.png?alt=media&token=7d99bfe4-5834-4760-b9a1-a0ed2b924a9b',
          id: 7,
        },
      ],
    }
    this.handleTabs = this.handleTabs.bind(this)
  }

  handleTabs(category) {
    this.setState({
      selectedTab: category,
    })
  }

  render() {
    // const storage = firebase.storage()
    return (
      <React.Fragment>
        <div className="container-work">
          <div className="container container-work">
            <div className="section">
              <Tabs selectedTab={this.state.selectedTab} handleTabs={this.handleTabs} />
              {this.state.projects.map((project) => (
                <Cards name={project.name} position={project.position} time={project.time} skills={project.skills} description={project.description} image={project.image} />
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Work
