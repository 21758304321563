import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

class Options extends Component {
	render() {
		const { title, path, hover } = this.props.data

		if (!title) {
			return null
		}

		console.log(title, hover, path)

		return (
			<React.Fragment>
					<div className="menu-item">
						{ path &&
							<NavLink exact to={path}>
								{title}
							</NavLink>
						}
						{hover &&
							<div className="hover-item">
								<div className="standard">{title}</div>
								<div className="hover">{hover}</div>
							</div>
						}
					</div>
			</React.Fragment>
		)
	}
}

export default Options