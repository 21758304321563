import React, { Component } from 'react'
// import Lottie from 'react-lottie'
// import Image from '../../images/beatrizbremer_homepage_blue_small.json'
// import { NavLink } from 'react-router-dom'

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="contact columns is-vcentered">
          <div className="column">
            <h1>beatriz@beatrizbremer.com</h1>
            <h2>+31 6 3989 5593</h2>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Contact
