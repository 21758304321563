import React, { Component } from 'react'
import Options from './Options/Options'

class Menu extends Component {
	render() {
		const data = this.props.menuItems
		console.log(data[1])

		if (!data) {
			return null
		}

		const renderOptions = data.map(option => (
			<Options data={option} key={option.id} />
		))

		return (
			<React.Fragment>
				<div class="menu" >
					{renderOptions}
				</div>
			</React.Fragment>
		)
	}
}

export default Menu