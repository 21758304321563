import React, { Component } from 'react'

class Tabs extends Component {
  render() {
    console.log(this.props.selectedTab)
    return (
      <React.Fragment>
        <div className="tabs is-centered is-boxed work-tabs">
          <ul>
            <li>
              <button onClick={() => this.props.handleTabs('all')} className={this.props.selectedTab === 'all' ? 'is-active' : ''}>
                <span className="icon is-small">
                  <i className="fas fa-image" aria-hidden="true"></i>
                </span>
                <span className="button-text">All</span>
              </button>
            </li>
            <li>
              <button onClick={() => this.props.handleTabs('ac')} className={this.props.selectedTab === 'ac' ? 'is-active' : ''}>
                <span className="icon is-small">
                  <i className="fas fa-music" aria-hidden="true"></i>
                </span>
                <span className="button-text">a.C. (After Codam)</span>
              </button>
            </li>
            <li>
              <button onClick={() => this.props.handleTabs('bc')} className={this.props.selectedTab === 'bc' ? 'is-active' : ''}>
                <span className="icon is-small">
                  <i className="fas fa-film" aria-hidden="true"></i>
                </span>
                <span className="button-text">b.C. (Before Codam)</span>
              </button>
            </li>
            <li>
              <button onClick={() => this.props.handleTabs('education')} className={this.props.selectedTab === 'education' ? 'is-active' : ''}>
                <span className="icon is-small">
                  <i className="far fa-file-alt" aria-hidden="true"></i>
                </span>
                <span className="button-text">Education</span>
              </button>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default Tabs
