import React, { Component } from 'react'

class Cards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMore: false,
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="cards-component">
          <div className="card">
            <div className="card-image">
              <figure className="image is-4by3">
                <img src={this.props.image} alt="Placeholder" />
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">{this.props.name}</p>
                  <p className="subtitle is-6">{this.props.position}</p>
                  <p className="date">{this.props.time}</p>
                </div>
              </div>
              <div className="content">
                {this.state.showMore ? <div className="description">{this.props.description}</div> : <div className="description">{this.props.description}</div>}
                <div>
                  {this.props.skills.map((skill) => (
                    <span class="tag skills-tags">{skill}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Cards
