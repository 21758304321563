import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Homepage from './Views/Homepage/Homepage'
import Contact from './Views/Contact/Contact'
import Work from './Views/Work/Work'
import './App.scss'

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <Router>
        <div className="general-text">
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/work">
              <Work />
            </Route>
            {/* <Route>
                <NotFound type="404" />
              </Route> */}
          </Switch>
        </div>
      </Router>
      {/* </header> */}
    </div>
  )
}

export default App
