import React, { Component } from 'react'
// import Lottie from 'react-lottie'
// import Image from '../../images/beatrizbremer_homepage_blue_small.json'
// import { NavLink } from 'react-router-dom'
import Menu from '../../Components/Menu/Menu'

class Homepage extends Component {
  render() {
    // const defaultImage = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: Image,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice',
    //   },
    // }
    const menuItems = [
      {
        hover: 'COMING SOON',
        title: "WORK",
        id: '1'
      },
      // {
      //   path: 'wedding',
      //   title: 'BEA&LUC',
      //   id: '2'
      // },
      {
        hover: 'BEATRIZ@BEATRIZBREMER.COM',
        title: 'CONTACT',
        id: '3'
      },
      {
        hover: 'COMING SOON',
        title: 'RANDOM',
        id: '4'
      }
    ]

    return (
      <React.Fragment>
        <div className="homepage">
          <div className="image">
            {/* <Lottie options={defaultImage} width={800} /> */}
          </div>
          <div className="image-phone">
            {/* <Lottie options={defaultImage} width={320} /> */}
          </div>
          <Menu menuItems={menuItems} />
           {/* <div class="menu" >
            <div>
              <div className="btn-homepage">
                <NavLink exact to="/work">
                  WORK
                </NavLink>
              </div>
              <div className="btn-homepage">
                <NavLink exact to="/news">
                  SAHS12
                </NavLink>
              </div>
            </div>
            <div>
              <div className="btn-homepage">
                <NavLink className="show-contact contact-button" exact to="/contact">
                  CONTACT
                </NavLink>
                <div className="contact-card">
                  <p>
                    beatriz@beatrizbremer.com
                    <br></br>
                    +31 6 3989 5593
                  </p>
                </div>
              </div>
              <div className="btn-homepage">
                <NavLink exact to="/random">
                  RANDOM
                </NavLink>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </React.Fragment>
    )
  }
}

export default Homepage
